export default [
  {
    id: 1,
    name: 'Britney',
    profileUrl: 'assets/img/avatars/Britney.jpg',
  },
  {
    id: 2,
    name: 'Maleena',
    profileUrl: 'assets/img/avatars/Maleena.jpeg',
  },
  {
    id: 3,
    name: 'Kathie',
    profileUrl: 'assets/img/avatars/kathie.jpeg',
  },
  {
    id: 4,
    name: 'Shosho',
    profileUrl: 'assets/img/avatars/Sosho.jpeg',
  },
  {
    id: 5,
    name: 'Emalie',
    profileUrl: 'assets/img/avatars/Emalie.jpg',
  },
  {
    id: 6,
    name: 'Sasha',
    profileUrl: 'assets/img/avatars/sasha.jpeg',
  },
  {
    id: 7,
    name: 'Ramona',
    profileUrl: 'assets/img/avatars/Ramona.jpeg',
  },
  {
    id: 8,
    name: 'Nethara',
    profileUrl: 'assets/img/avatars/Nethara.jpg',
  },
  {
    id: 9,
    name: 'Nairobi',
    profileUrl: 'assets/img/avatars/Nairobi.jpg',
  },
  {
    id: 10,
    name: 'Neha',
    profileUrl: 'assets/img/avatars/Neha.jpg',
  },
]
